.communications-page .main-content {
  padding: 24px 30px;
}

.communications-page .main-content .backBtn {
  width: fit-content;
  margin-left: 0;
  margin-bottom: 6px;
  padding-left: 0;
}

.communications-page .main-content .title {
  margin-bottom: 29px;
}

.communications-page .tabs-container {
  display: flex;
  width: fit-content;
  color: #000F24;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px; /* 137.5% */
  letter-spacing: -0.408px;
  margin-bottom: 0px;
}

.communications-page .tabs-container button:first-child {
  border-top-left-radius: 10px;
}

.communications-page .tabs-container button:last-child {
  border-top-right-radius: 10px;
}

.communications-page .tabs-container .tab-seleccionada,
.communications-page .tabs-container .tab-no-seleccionada {
  display: flex;
  padding: 12px;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  border: none;
  min-width: 145px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.communications-page .tabs-container .tab-seleccionada {
  border-bottom: 2px solid #224A91;
  background: #DDE4EF;
}

.communications-page .navigation-bar {
  background: rgb(11,46,19);
  background: linear-gradient(90deg, #10182880 6%, rgba(0,212,255,0) 19%);
}

.communications-page .tabs-container .tab-no-seleccionada {
  background: #F9F9F9;
  color: var(--Gris-80, #808080);
}

.communications-page .tabs-content {
  padding: 30px;
  border-radius: 0px 8px 8px 8px;
  border: 1px solid var(--Gray-200, #EAECF0);
}
.communications-page .tabs-content .envios-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.communications-page .tabs-content .envios-container > div {
  width: 20%;
  gap: 20px;
  display: flex;
  flex-direction: column;
  min-width: 300px;
}

/* TAB DE ENVIOS */
.communications-page .envios-container .success-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.communications-page .envios-container .success-container img {
  width: 46px;
  height: 46px;
  border-radius: 23px;
  background: var(--Amarillo-claro, #FFEFD6);
}

.communications-page .envios-container .success-container p {
  color: var(--Azul-oscuro, #000F24);
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
}

.communications-page .envios-container .success-container Button {
  width: 100%;
  padding: 10px 32px;
}
/* END TAB DE ENVIOS */

/* TAB DE CONSULTAS */
.communications-page .consultas-container > div {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.communications-page .consultas-container button {
  max-width: 230px;
  align-self: center;
}

.communications-page .consultas-container .tooltip {
  position: absolute;
  top: 380px;
  left: 178px;
  display: inline-block;
  width: fit-content;
}

  /* SUCCESS */
  .communications-page .success-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
  }

  .communications-page .success-container .message,
  .communications-page .success-container .submessage {
    color: var(--Azul-oscuro, #000F24);
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 20px;
  }

  .communications-page .success-container .message {
    margin-top: 0;
    margin-bottom: 8px;
  }

  .communications-page .success-container .submessage {
    font-size: 14px;
    font-weight: 400;
  }

  .communications-page .success-container .submessage b {
    font-weight: 500;
  }

  .communications-page .success-container .buttons-container {
    display: flex;
    gap: 8px;
  }
  /* END SUCCESS */
  /* ERROR */
  .communications-page .error-container {
    display: flex;
    gap: 30px;
    align-items: center;
  }

  .communications-page .error-container > div {
    display: flex;
    gap: 20px;
    flex-direction: column;
    align-items: center;
  }
  /* END ERROR */

  /* MODAL */
  .communications-page .success-container .modal {
    display: flex;
    width: 560px;
    padding: 16px 16px 48px 16px;
    flex-direction: column;
    justify-content: center;
    gap: 30px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    border-radius: 5px;
    z-index: 1000;
    color: var(--gray-700, #344054);
    font-size: 14px;
    font-weight: 400;
    line-height: 28px;
  }

  .communications-page .success-container .modal .modal-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .communications-page .success-container .modal .modal-header img {
    cursor: pointer;
    width: 18px;
    height: 18px;
  }

  .communications-page .success-container .modal .modal-body p {
    margin: 0;
  }

  .communications-page .success-container .modal .modal-body p:first-of-type {
    color: var(--gray-900, #101828);
    font-size: 18px;
    font-weight: 600;
    line-height: 28px;
  }

  .communications-page .success-container .modal .modal-body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  }
  .communications-page .success-container .modal Select {
    align-self: center;
    width: 85%;
  }

  .communications-page .success-container .modal .modal-footer {
    display: flex;
    justify-content: center;
    gap: 12px;
  }

  .communications-page .success-container .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }

  .communications-page .error-container .modal-backdrop{
    width: 100%;
  }

.communications-page .error-container .modal-header{
  width: 100%;
}

  /* END MODAL */

/* END TAB DE CONSULTAS */
