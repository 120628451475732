.home-page .main-image {
  height: 130px;
  display: flex;
  align-items: center;
}

.home-page .main-content {
  padding: 60px;
}

.home-page .navigation-bar {
  background: #10182820;
}

.home-page h2 {
  color: var(--gray-900, #101828);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
  margin-bottom: 20px;
}

.home-page .module-container {
  display: flex;
  gap: 24px;
  justify-content: center;
}

.home-page .module-container img {
  height: 90px;
}

.home-page .module-container a {
  display: flex;
  height: 183px;
  max-width: 300px;
  padding: 20px 20px 37px 20px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  flex: 1 0 0;
  box-sizing: border-box;
  background: #FFF;
  color: var(--Azul-oscuro, #000F24);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-decoration: none;
  text-align: center;
}

.home-page .logo-img{
  display: none;
}
.home-page .logo-img2{
  padding-left: 30px;
}

.module-container a img:last-child {
  display: none;
}
.module-container a:hover img:last-child {
  display: block;
}
.module-container a:hover img:first-child {
  display: none;
}
